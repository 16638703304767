/* You can add global styles to this file, and also import other style files */
.site-content .container{
    margin-top: 70px;
}
h1.appTitle{
    margin-bottom: 5px;
}
.floatLeft{
    float: left;
}
.floatRight{
    float: right;
}
.infoRight{
    float: right;
    margin-top: 15px;
    max-width: 50%;
}
.infoRight .alert{
    margin-bottom: 0px;
}
.clearFloat{
    clear: both;
    height: 0px;
}
.zeroMargin{
    margin: 0px;
}
.btn{
    min-width: 120px;
}
.btn-xlarge{
    width: 170px;
}
html, body{
    height: auto;
}
.table .thead-light th {
    color: #495057;
}
.fullview{
    min-height: calc(100vh - 157px);
}
.rowGap{
    padding-top: 50px;
}
.rowBigGap{
    margin-top: 50px;
}
footer{
    /* color: #2e2e2e;
    position: relative;
    background-color: #eaedf8; */
    position: relative;

}
.text-bold{
    font-weight: bolder;
}
table tbody tr td .progress{
    height: 12px;
    margin-top: 5px;
    position: relative;
}
table tbody tr td a.btn{
    padding: 0px;
    font-size: 16px;
    color: #3e53a4 !important;
}
.emptyPanelMsg{
    display: flex;
    justify-content: center;
    align-items: center;
    height: calc(100vh - 400px);
}
.errorPos{
    position: relative;
    top: 10px;
    width: 530px;
    margin: 0 auto;
}
.modal.show, .modal-backdrop.show{
    display: block;
}
.modal-backdrop{
    display: none;
}
.spinner-sample{
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    display: none;
    background-color: #000000;
    opacity: 0.5;
    z-index: 9999;
}
.spinner-sample.show{
    display: block;
}
.tooltipContent .floatLeft{
    text-align: right;
}
.tooltipContent .floatRight{
    text-align: left;
}
.spinner-sample i.pbi-loader-circle{
    display: flex;
    height: 100vh;
    justify-content: center;
    align-items: center;
}
a.fileLink{
    position: relative;
    color: #3e53a4 !important;
    cursor: pointer;
}
.helpLabel{
    font-size: 12px;
}
.dashboardTbl .ui-table-wrapper table tbody tr td:nth-child(2){
    text-transform: uppercase;
}
.ui-table-wrapper{
    overflow-x: auto;
}
.ui-table thead th div.ellipsis{
    width: 150px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.ui-table tbody td div.ellipsis{
    width: 150px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    word-break: break-all;
}
.mappingDrop a{
    display: inline-block;
    width: 80%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.dropContainer {
	height:57px;
	width:100%;
	border-style:solid;
	border-color:#9b9b9b;
	border-width: 1px 1px 1px 1px;
	border-radius:10px;
	padding: 5px;
}
/* in-flight clone */
.gu-mirror {
    position: fixed !important;
    margin: 30px 0px 0px 0px !important;
    z-index: 9999 !important;
    /*opacity: 0.8;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=80)";
    filter: alpha(opacity=80);*/
    pointer-events: none;
    background-color: #f8f8f8;
    border: 1px dashed #c8cee4;
    padding: 12px 5px;
    height: 41px !important;
    font-weight: bold;
    /*width: 210px !important;*/
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  /* high-performance display:none; helper */
  .gu-hide {
    left: -9999px !important;
  }
  /* added to mirrorContainer (default = body) while dragging */
  .gu-unselectable {
    -webkit-user-select: none !important;
    -moz-user-select: none !important;
    -ms-user-select: none !important;
    user-select: none !important;
  }
  /* added to the source element while its mirror is dragged */
  .gu-transit {
    /*opacity: 0.2;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=20)";
    filter: alpha(opacity=20);*/
    background-color: #eaecf8;
    height: 45px;
    color: #0072b8;
    padding: 10px;
    /*width: 170px !important;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;*/
  }
  .disabledRow, .disabledRow:hover{
      background-color: #9b9b9b !important;
      color: #f8f8f8;
  }
  .disabledRow:after{
    content: '*';
  }
  .hiddenInput{
      display: none;
  }
  .mappingDrop{
      height:45px;
      width: 100%;
      border-style:solid;
      border-color:#9b9b9b;
      border-width: 1px 1px 1px 1px;
      border-radius:10px;
      padding: 10px;
      background-color: #eaecf8;
      color:#0072b8
  }
  .mappingDrop i{
      float: right;
      font-size: 20px;
      font-weight: bold;
      color: #717173;
      cursor: pointer;
  }
@media all and (-ms-high-contrast:none){
    *::-ms-backdrop, .uploadFileArea {
        margin-top: 50px;
        margin-bottom: 50px;
    } /* IE11 */
}
@media (min-width: 576px){
    .modal-custom.modal-dialog{
        max-width: 90% !important;
    }
}
.modal-custom .rowgap{
    padding: 10px 0px;
}
.btn + .btn{
    margin-left: 30px;
}
table tr td .btn + .btn{
    margin-left: 0.25rem;
}
.customColumns{
    -webkit-box-flex: 0;
    -ms-flex: 0 0 20%;
    flex: 0 0 20%;
    max-width: 20%;
    position: relative;
    width: 100%;
    min-height: 1px;
    padding-right: 10px;
    float: left;
}
.customColumns:last-child{
    padding-right: 0px;
}
.dropContainer.highLight{
    border: 1px solid #ff0000;
}
.blankBtn{
    width: 23px;
    cursor: auto !important;
}
.rightPane{
	border-left: 1px solid #cccccc;
	min-height: calc(100vh - 315px);
}
.rightPane.rightPaneFree{
	min-height: calc(100vh - 40px);
}
.formRow{
	padding: 20px 0px 0px 0px;
}
.formRow label{
	margin: 0px;
}
.rightPane .table{
    margin-bottom: 10px;
}
.totalWithTax{
    margin-bottom: 30px;
    text-align: right;
    padding: 0px 12px 0px 0px;
}
.customfileupload{
    position: relative;
}
.customfileupload span.filefield{
    display: block;
    width: calc(100% - 120px);
    height: 39px;
    border-top: 1px solid #c0c0c0;
    border-left: 1px solid #c0c0c0;
    border-bottom: 1px solid #c0c0c0;
    float: left;
    border-radius: 4px 0px 0px 4px;
    padding: 10px 12px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.customfileuploadbtn{
    border-radius: 0px 4px 4px 0px;
    width: 120px;
}
#taxAmountFile{
    position: absolute;
    height: 40px;
    width: 100%;
    opacity: 0;
    top: 0;
    left: 0;
}
.uploadstep{
    font-size: 18px;
}
@-moz-document url-prefix() {
    #taxAmountFile{
        padding: 0px;
    }
}
table.customtbl tr td{
    padding-left: 0px !important;
    padding-right: 0px !important;
}
.relativePos{
	position: relative;
}
.clear{
	clear: both;
}
.dangericon{
	font-size: 18px;
	margin-right: 10px;
}
#billPeriodDiv {
    text-align: center;
    font-size: 11px;
    margin-top: 10px;
}
.pbds-chart.pbds-chart-gauge .gauge-details .gauge-number {
    color: #000000;
    font-size: 36px;
}
.pbds-chart.pbds-chart-gauge .gauge-details .gauge-description {
    color: #000000;
    font-size: 10px;
    margin-top: 5px;
}
.printbtn{
    min-width: 40px;
    margin: 0px 0px 0px 20px;
}
.printTaxrateAmt, .printClear, .printMatchedAddress, .printConfidenceScore{
	display: none;
}
.geotaxRowgap{
	margin-top: 30px;
}
.leftPad{
	padding: 0px 0px 0px 20px;
}
.bottomPad{
	padding: 0px 0px 20px 0px;
}
#creditleft .overageTxt{
	color: #E5007E;
	font-weight: bold;
}
.headerBtn{
    float: right;
    margin-top: 2rem;
}
.leaflet-container a{
    color: #8017e0 !important;
}
.sampleDemoPbLogo{
  position: absolute;
  bottom: 0px;
  z-index: 999;
  width:100px;
  height:33px;
}
.geoApiSampleDemoMapContainer{
    position: relative;
    height:290px;
}
.geoApiSampleDemoMap {
    z-index: 1;
    position: absolute;
    width: 100%;
    height: 270px;
}
.leaflet-top .leaflet-control {
    margin-top: 10px;
}
.badge{
    cursor: pointer;
}
.cursorpointer{
    cursor: pointer;
}
.card .card-body h4.autoHeight{
	height: auto;
	margin-bottom: 15px;
}
#makemodelcontainer .dropdown-menu.show {
    display: block;
    max-height: 300px;
    width: calc(100% - 30px);
    overflow-y: auto;
    overflow-x: hidden;
}
#taxRateResult .table {
    border: 1px solid var(--border_color);
}
#taxRateResult .table thead th{
    background-color: #f8f8f8;
    text-transform: none;
    font-size: 13px;
}
#taxRateResult .table thead th.topborder{
    border-top: 1px solid var(--border_color);
}
.form-control[disabled], select.form-control[disabled]{
    color: var(--text);
    -webkit-text-fill-color: var(--text);
    opacity: 1;
}
.middleCardPanel{
	display: block;
	margin-top: 10px;
}
.middleCardPanel .data-widget{
    width: 330px;
    margin: 0 auto;
}
.middleCardPanel .data-widget .data-widget-data{
    padding: 15px 20px;
}
.data-widget-purple{
    background-image: linear-gradient(135deg, #8017E1 0%, #8017E1 50%, #8017E1 100%);
}
.size{
font-size: 0.900rem;

}
.pbds-chart.pbds-chart-gauge .gauge-details.gauge-details-small .gauge-number{
    visibility: hidden;
}
.custom-gauge-number{
    text-align: center;
    position: absolute;
    width: 100%;
    top: 41px;
    z-index: 10;
}
.custom-gauge-number span{
    font-size: 26px;
    font-weight: bold;
}
.custom-gauge-number i{
    font-size: 18px;
}
.customToolTip{
    position: absolute;
    top: 0px;
    left: 0px;
    display: none;
    z-index: 0;
    width: 200px;
}
.customToolTip .tooltipContent{
    color: #ffffff;
    border-radius: 3px;
    text-align: center;
    padding: 3px;
    justify-content: center;
    background-color: #353536;
    display: flex;
    font-size: 13px;
}
.customToolTip i.customTip{
    font-size: 18px;
    color: #353536;
    line-height: 12px;
    position: absolute;
    top: 42px;
}
.customToolTip.show{
    display: block;
}
@media screen and (max-width: 1000px) {
	.rightPane{
		border-left: 0 none !important;
	}
	.middleCardPanel{
		display: none;
	}
}
.recurly-element, .recurly-hosted-field {
    height: calc(1.5em + 1rem + 2px);
}
.geotaxTopBanner{
	background: transparent url('/assets/images/geotax-hero-background-image.jpg') no-repeat center center;
	padding: 160px 0px 0px 0px;
    height: 570px;
    background-size: cover;
}
.geotaxTopBannerContent{
    position: relative;
    max-width: 1000px;
    margin: 0 auto;
}
.geotaxTopBanner h1{
	margin-top: 0px;
	margin-bottom: 0px;
	color: #ffffff;
	font-size: 60px;
    font-family: 'Precisely_Light';
}
.geotaxTopBanner h3{
	color: #ffffff;
    margin: 0px;
    font-size: 32px;
    font-family: 'Precisely_Light';
}
.geotaxTopBanner sup{
    font-size: 18px;
    font-weight: bold;
    top: -1.3em;
}
.geotaxMiddleBanner{
	text-align: center;
	padding: 60px 80px;
}
.geotaxMiddleBanner h1, .geotaxMiddleBanner h2{
	margin-top: 0px;
	margin-bottom: 0px;
	color: #2e2e2e;
}
.geosearchMiddleBanner{
	background-color: #39006B;
	text-align: center;
	padding: 50px 0px;
}
.geosearchMiddleBanner h3{
    font-size: 24px;
    max-width: 750px;
    margin: 0px auto 50px auto;
    font-family: 'Precisely_Regular';
    color: #ffffff;
}
.customerBanner{
    background: transparent url('/assets/images/geotax-client-feedback-background-image.jpg') no-repeat center top;
    background-size: cover;
	padding: 50px 0px;
}
.testimonyprofileimg{
	text-align: center;
}
.testimonyprofileimg img{
	width: 350px;
}
.testimony{
	padding: 45px 10px;
}
.content-container{
    max-width: 1000px;
    margin: 0 auto;
}
.customerBanner h1{
	margin-top: 0px;
	color: #ffffff;
	font-size: 50px;
	line-height: 1;
}
.customerBanner h3{
	color: #ffffff;
	font-size: 20px;
	line-height: 1.5;
	margin: 0px;
}
.geotaxCustomerList{
    padding: 0px;
    max-width: 1000px;
    margin: 0 auto;
}
.companylogo{
	height: 60px;
	width: 166px;
	background-repeat: no-repeat;
	background-position: center center;
	margin: 0 auto;
	background-size: 100px;
}
.co-logo-bayada{
	background-image: url('/assets/images/co-logo-bayada.png');
}
.co-logo-carmax{
	background-image: url('/assets/images/co-logo-carmax.png');
}
.co-logo-fortegra{
	background-image: url('/assets/images/co-logo-fortegra.png');
}
.co-logo-geico{
	background-image: url('/assets/images/co-logo-geico.png');
}
.co-logo-cincinnati{
	background-image: url('/assets/images/co-logo-cincinnati.png');
}
.co-logo-att{
	background-image: url('/assets/images/co-logo-att.png');
}
.co-logo-verizon{
	background-image: url('/assets/images/co-logo-verizon.png');
}
.co-logo-xcelenergy{
	background-image: url('/assets/images/co-logo-xcelenergy.png');
}
.co-logo-campingworld{
	background-image: url('/assets/images/co-logo-campingworld.png');
}
.co-logo-johnsoncontrols{
	background-image: url('/assets/images/co-logo-johnsoncontrols.png');
}
.co-logo-lowes{
	background-image: url('/assets/images/co-logo-lowes.png');
}
.co-logo-macys{
	background-image: url('/assets/images/co-logo-macys.png');
}
.co-logo-officedepot{
	background-image: url('/assets/images/co-logo-officedepot.png');
}
.co-logo-toyota{
	background-image: url('/assets/images/co-logo-toyota.png');
}
.customerList{
	padding: 0px 20px;
}
.customerList h1{
    text-align: center;
    color: #2e2e2e;
}
.customerList table tbody tr td{
	text-align: center;
}
.planList{
    background-color: #f8f8f8;
    padding: 50px 40px;
}
.planList h1{
    text-align: center;
    color: #2e2e2e;
    margin-top: 0px;
}
.rowgap{
    padding: 10px 0px;
}
.card-price{
    min-height: 106px;
}
.card-description{
	min-height: 83px;
}
.card:hover{
    -webkit-box-shadow: 0 0 7px 5px #dddddd;
    -moz-box-shadow: 0 0 7px 5px #dddddd;
    box-shadow: 0 0 7px 5px #dddddd;
}
#overageMessageModal{
    top: 100px;
}
#modalBackdrop{
    opacity: 0.5;
}
a.customLink{
    color: #3e53a4 !important;
    text-decoration: none !important;
    cursor: pointer;
}
.hiddenOnDevice{
    display: block;
}
.showOnDevice, .inlineOnDevice{
    display: none;
}
.noPadding{
    padding: 0px;
}
.site-content{
    margin-top: 70px;
    margin-bottom: 20px;
}
.geosearchTopBanner{
	background: transparent url('/assets/images/precisely-api-developer-hub-hero.jpg') no-repeat center top;
	text-align: center;
	padding: 100px;
    background-size: cover;
}
.geosearchTopBanner h1{
	margin-top: 0px;
	color: #ffffff;
	font-size: 42px;
	line-height: 1;
}
.geosearchTopBanner h2{
	color: #ffffff;
	font-size: 20px;
    line-height: 1.2;
    margin-top: 40px;
	margin-bottom: 45px;
}
.geosearchTopBanner a.btn{
	background-color: transparent;
	border-color: #ffffff;
    width: 170px;
}
.columnVerticalTop{
    vertical-align: top;
}
.width92percent{
    width: 92%;
}
.width85percent{
    width: 85%;
}
.width320px{
    width: 320px;
}
#recurly-form, #submitPaymentBtn, #reEnterAddrBtn{
    display: none;
}
.pricing-plan-cards{
    grid-template-columns: repeat(3, 1fr)
}
.pricing-plan-card-featured .plan-price, .pricing-plan-card .plan-title, .pricing-plan-card .plan-price {
    color: #111111;
}
.btn-card-link{
    color: #8017E1;
}
.text-red{
    color: #FF0000;
    font-size: 12px;
}
.privacy-policy-icon{
    padding-left: 3rem;
    position: relative;
}
.privacy-policy-icon::before{
    content: "";
    background-image: url(assets/images/privacy-choices.svg);
    background-repeat: no-repeat;
    background-size: 2.625rem auto;
    position: absolute;
    left: 0;
    top: .0625rem;
    width: 2.625rem;
    height: 1.25rem;
}